<!--
 * @Author: DY
 * @Date: 2020-12-29 12:19:15
 * @LastEditTime: 2021-09-29 11:08:50
 * @Description:
 * @Param:
 * @FilePath: \JT_Web_test\src\views\salesBusiness\pop\modifyContractStatus.vue
-->
<template>
    <div class="draftContractPop">
        <div class="types" v-if="extr.statusType === 'normal'">
            <div class="types-row">
                <el-radio :label="10" v-model="status">
                    正常供应
                </el-radio>
                <el-radio :label="20" v-model="status">
                    暂停供应
                </el-radio>
            </div>
            <div class="types-row">
                <el-radio :label="30" v-model="status">
                    完成供应
                </el-radio>
                <el-radio :label="40" v-model="status">
                    关闭合同
                </el-radio>
            </div>
        </div>
        <div class="types" v-if="extr.statusType === 'transformation'">
            <div class="types-row">
                <el-radio :label="2" v-model="status">
                    转换中（正常供应）
                </el-radio>
                <el-radio :label="21" v-model="status">
                    转换中（暂停供应）
                </el-radio>
            </div>
        </div>
        <div class="text-center">
            <el-button type="primary" @click="onSubmit">
                确定
            </el-button>
            <el-button @click="closeFn">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            status: 0,
        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            if (!this.status) {
                this.$message.warning('请选择更改状态！');
                return;
            }
            this.$axios
                .put('/interfaceApi/sale/contract_manager/contract_status/' + this.extr.rowData.cvid + '/' + this.status)
                .then(() => {
                    this.$message.success('修改成功');
                    window.hideToast();
                    window.updateTable();
                })
                .catch(error => {
                    this.$message.error(error);
                });

        },
        closeFn() {
            window.hideToast();
        },
    },
    created() {
        this.status = this.extr.rowData.contract_status;
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.draftContractPop
    .types
        padding .2rem
        text-align center
        .types-row
            margin .2rem 0
    .text-center
        text-align center
        position absolute
        bottom .1rem
        width 100%
        .el-button
            width 1.2rem
            height 0.36rem
            padding 0
            margin 0 0.2rem
</style>